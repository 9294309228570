<template>
  <div class="container">
    <div class="form-box">
      <h1 class="page-title">Сброс пароля</h1>
      <form class="form">
        <the-mask
          id="login"
          type="tel"
          :class="['form__input', {'invalid': $v.login.$error}]"
          :mask="['+7 (###) ###-##-##']"
          v-model.trim="$v.login.$model"
          placeholder="ВАШ НОМЕР ТЕЛЕФОНА"
          autocomplete="off"
        />
        <div class="helper-text text-red" v-if="$v.login.dirty && !$v.login.required">Заполните это поле</div>
        <div class="helper-text text-red" v-if="!$v.login.numeric">Должно содержать только цифры</div>
        <div class="helper-text text-red" v-if="!$v.login.minLength">Не должно быть меньше {{$v.login.$params.minLength.min}} символов</div>
        <div class="helper-text text-red" v-if="!$v.login.maxLength">Не должно быть больше {{$v.login.$params.maxLength.max}} символов</div>

        <div class="form__restore">
          <button
            @click.prevent="submitHandler()"
            class="btn lg btn--restore"
          >ВОССТАНОВИТЬ</button>

          <router-link
            tag="button"
            to="/login"
            class="btn lg btn--cancel"
          >НАЗАД</router-link>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { required, minLength, maxLength, numeric } from 'vuelidate/lib/validators'

export default {
  name: 'RestorePassword',

  data: () => ({
    isLoading: false,
    login: '',
  }),

  validations: {
    login: {
      required,
      numeric,
      minLength: minLength(10),
      maxLength: maxLength(10),
    }
  },

  methods: {
    ...mapActions(['restorePassword']),
    lock() { this.isLoading = true },
    unlock() { this.isLoading = false },
    async submitHandler() {

      if (this.$v.$invalid) {
        this.$v.login.$touch()
        M.Toast.dismissAll()
        M.toast({ html: 'Пожалуйста, заполните указанные поля!' })
        return;
      }

      let formData = {
        login: (this.login.substr(0,1) === '7') ? this.login : `7${this.login}`
      }

      try {
        this.lock()
        await this.restorePassword(formData)
        M.toast({ html: 'На указанный вами номер был выслан новый пароль' })
        this.$router.push('/login')
      }
      finally {
        this.unlock()
      }
    }
  }
}
</script>
